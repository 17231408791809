<template>
<div>
  <v-data-table
      ref="table"
      :search="appBarSearch"
      :no-data-text="$t('no-data-available')"
      :loading-text="$t('loading')"
      dense :height="pageHeight(150)"
      :headers="headers"
      fixed-header
      class="medical-staff-data-table"
      :items="filteredNotes"
      :footer-props= "getDataTableFooterProps('medicalNotesCategoryTable')"
      :items-per-page="-1"
      group-by="officeName"
  >
    <template  v-slot:[`group.header`]="{ headers, items, toggle, group, isOpen}">
      <td @click="toggle" class="elevation-1 px-1 py-0 white-background" :colspan="8">
        <v-row class="px-0" dense >
          <v-col cols="10" class="d-flex text-left py-0">
            <v-btn class="my-0 " small icon :ref="group" :data-open="isOpen">
              <v-icon  small v-if="isOpen" color="primary">mdi-arrow-down-drop-circle</v-icon>
              <v-icon small v-else color="primary">mdi-arrow-right-drop-circle</v-icon>
            </v-btn>
                <span class="font-weight-bold ml-1 font-size12 mt-2 pl-2 " v-if="items && items.length > 0">
                  {{items[0].officeName}}
                </span>
          </v-col>
        </v-row>
      </td>
    </template>
    <template v-slot:item="{ item , index }">
      <tr v-if="index === 0">
        <td class="secondary text-left" v-for="(header,index) in insideHeaders" :key="index">
          <span class="font-weight-medium font-size11">{{ header.text }}</span>
        </td>
        <td v-if="endHeader[1].text==='Status'" class="secondary text-right">
          <div >
            <span class="font-weight-medium font-size11">{{ endHeader[1].text }}</span>
          </div>
        </td>
        <td v-if="endHeader[0].text==='Actions'" class="secondary text-right">
          <div >
            <span class="font-weight-medium font-size11">{{ endHeader[0].text }}</span>
          </div>
        </td>
      </tr>
      <tr class="ma-0 pa-0">
        <td class="text-left font-weight-medium font-size12-5">{{item.medicalNoteCategoryName}}</td>
        <td class="text-left  font-weight-medium font-size12-5 clip-text">{{item?.medicalNoteCategoryDescription}}</td>
        <td class="text-left font-weight-medium font-size12-5 ">{{item.officeName}}</td>
        <td class="text-left  font-weight-medium font-size12-5">{{ formatBillingTime(item.billingSecondsForThisMedicalNoteCategory) }}</td>
        <td class="text-right">	<v-icon small class="mr-2 pl-4" :style="item.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon></td>
        <td class="text-left  font-weight-medium font-size12-5">
          <v-menu
              left
              bottom
              class="py-0"
              offset-y
              transition="slide-x-transition"
          >
            <template v-slot:activator="{on, attrs}">
              <v-icon class=" mr-1 float-right" color="black"   v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
            </template >
            <v-list class="px-0" dense >
              <v-list-item class="py-0 my-0" link dense @click="sinhronizeDrawers(item)">
                <v-list-item-icon class="mx-1 ">
                  <v-icon class="icon-img mdi-18px" color="black">mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-bold font-size14">{{$t("edit")}}</v-list-item-content>
              </v-list-item>
              <v-divider/>
              <v-list-item link dense @click="handleToggleStatus(item)">
                <v-list-item-icon class="mx-1 ">
                  <v-icon class="icon-img mdi-18px" color="black">mdi-refresh</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-bold font-size14" >{{ $t('change') }} {{ $t('status') }}</v-list-item-content>
              </v-list-item>
              <v-list-item  link dense @click="openDialog(item)">
                <v-list-item-icon class="mx-1 ">
                  <v-icon color="red">mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-bold font-size14" >{{ $t('delete') }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </template>
  </v-data-table>
  <medical-note-delete-dialog
      v-if="dialogDelete"
      :dialog="dialogDelete"
      :selectedItemId="selectedItemId"
      @update:dialog="dialogDelete = $event"
      @update:selectedItemId="selectedItemId = $event"
  />
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getDataTableFooterProps, getRowsForDataTable, toggleHeaders } from '@/utils/utilities';
import medicalNoteDeleteDialog from '@/views/DrawerDialogs/MedicalNoteDeleteDialog.vue';

export default {
  components: {
    medicalNoteDeleteDialog,
  },
  data () {
    return {
      dialogDelete: false,
      selectedItemId: null,
      insideHeaders: [
        { text: this.$t('category'), value: '', sortable: true, class: 'secondary', align: 'start' },
        { text: this.$t('description'), value: 'medicalNoteCategoryDescription', sortable: false, class: 'secondary', align: 'start' },
        { text: this.$t('office-name'), value: 'officeName', sortable: false, class: 'secondary', align: 'start' },
        { text: this.$t('Billings'), sortable: false, class: 'secondary', align: 'start' },
      ],
      endHeader: [
        { text: this.$t('actions'), sortable: false, class: 'secondary', align: 'center' },
        { text: this.$t('status'), sortable: false, class: 'secondary', align: 'end' },
      ],
    };
  },
  async mounted () {
    await this.$store.dispatch('filterbar/AppBarUserStatusVerifier', 1).then(res => {
        this.getMedicalNoteTemplate();
    });
    if (this.roleName === 'ORGANIZATION_ADMIN') {
      this.toggleHeaders();
    }
  },
  methods: {
    toggleHeaders,
    openDialog (item) {
      this.dialogDelete = true;
      this.selectedItemId = item.id;
    },
    getRowsForDataTable,
    getDataTableFooterProps,
    async getMedicalNoteTemplate () {
      await this.$store.dispatch('medicalnotecategory/medicalnotecategorytemplate').then(res => {
        this.toggleHeaders();
      });
    },
    handleToggleStatus (item) {
      const body = {
        id: item.id,
        status: item.status === 0 ? item.status = 1 : item.status = 0,
      };
      this.$store.dispatch('medicalnotecategory/medicalnotecategorytemplatechangestatus', body).then(res => {
        this.getMedicalNoteTemplate();
      });
    },
    formatBillingTime (seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      if (this.$i18n.locale === 'en') {
        return `${minutes}m ${remainingSeconds}s`;
      } else {
        return `${minutes} minutos ${remainingSeconds}segundos`;
      }
    },
    sinhronizeDrawers (item) {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: item,
        page2: 'medical notes',
        item: item,
      });
    },
  },
    computed: {
    ...mapState({
      medicalNotesCategories: state => state.medicalnotecategory.medicalNotesCategories,
    }),
    ...mapGetters({
      roleName: 'authentication/getRole',
      pageHeight: 'pageHeight',
      appBarSearch: 'filterbar/getSearch',
      appBarUserStatusVerifier: 'filterbar/getUserStatusVerifier',
    }),
    headers () {
        const headers = [
          { text: this.$t('facility-name'), value: 'medicalNoteCategoryName', sortable: true, class: 'secondary', align: 'start' },
          { text: this.$t(''), sortable: false, value: 'medicalNoteCategoryDescription', class: 'secondary', align: 'start' },
          { text: this.$t(''), sortable: false, class: 'secondary', align: 'start' },
          { text: this.$t(''), sortable: false, class: 'secondary', align: 'start' },
          { text: this.$t(''), sortable: false, class: 'secondary', align: 'start' },
          { text: this.$t(''), sortable: false, class: 'secondary', align: 'start' },
        ];
        return headers;
    },
      filteredNotes () {
        // const searchTerm = this.appBarSearch ? this.appBarSearch.toLowerCase() : '';
        const statusText = {
          0: 'Inactive',
          1: 'Active',
          3: 'All',
        };
        const filteredItems = this.medicalNotesCategories.filter(item => {
          // Why do we need the appBarUserStatusVerifier here? Logged in as hospital admin. -- Leo
          return (
              (this.appBarUserStatusVerifier === 1 && item.status === 1) ||
              (this.appBarUserStatusVerifier === 0 && item.status === 0) ||
              (this.appBarUserStatusVerifier === 3)
          );
        });
        // if (searchTerm) {
        //   // Also the search here can be done with values in the headers. -- Leo
        //   filteredItems = filteredItems.filter(item => {
        //     const medicalNoteCategoryName = item.medicalNoteCategoryName && item.medicalNoteCategoryName.toLowerCase();
        //     const medicalNoteCategoryDescription = item?.medicalNoteCategoryDescription && item?.medicalNoteCategoryDescription.toLowerCase();
        //     const organizationName = item?.officeName && item?.officeName.toLowerCase();
        //     return medicalNoteCategoryName.includes(searchTerm) || medicalNoteCategoryDescription.includes(searchTerm) || organizationName.includes(searchTerm);
        //   });
        // }
        return filteredItems.map(item => ({
          ...item,
          statusText: statusText[item.status] || 'Unknown',
        }));
      }
,
  },
};
</script>

<style scoped>

</style>
