<template>
<div>
  <v-dialog v-model="localDialog" max-width="380px"  transition="dialog-top-transition">
    <v-card class="pa-7">
      <v-card-title class="d-flex justify-center align-center">
        <v-row  dense>
          <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background-alert">
                  <v-icon dense color="main_red" size="30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
          </v-col>
          <v-col cols="12">
            <span>{{ $t('deleteQuestion') }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
      <v-card-actions class="mb-2">
        <v-row dense>
          <v-col cols="12">
            <v-btn block depressed color="red" class="text-color-white" @click="deletemedicalnotecategorytemplate" >{{ $t('deleteField') }}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn outlined depressed color="black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>

import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
  name: 'MedicalNoteDeleteDialog.vue',
  props: ['dialog', 'selectedItemId'],
  data () {
    return {
    localDialog: this.dialog,
      localSelectedItemId: this.selectedItemId,
    };
  },
  methods: {
    deletemedicalnotecategorytemplate () {
      this.$store.dispatch('medicalnotecategory/deletemedicalnotecategorytemplate', this.localSelectedItemId)
          .then(res => {
            showSuccessAlert(this.$t('success-operation'));
            this.localDialog = false;
            this.$store.dispatch('medicalnotecategory/medicalnotecategorytemplate');
          })
          .catch(error => {
            if (error.message === '403 Forbidden Error') {
              showErrorAlert(this.$t('not-authorized-to-delete-medical-note'));
            } else {
              showErrorAlert(this.$t('not-authorized-to-delete-medical-note'));
            }
          });
    },
    closeDeleteDialog () {
      this.localDialog = false;
      this.localSelectedItemId = null;
    },
  },
  watch: {
    localDialog (val) {
      this.$emit('update:dialog', val);
    },
    localSurveyId (val) {
      this.$emit('update:selectedItemId', val);
    },
  },

};
</script>

<style scoped>

</style>
